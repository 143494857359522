// adding an overlay to form
// importing react and form
import React from 'react';
import Form from './Form/index.jsx';
// contact component
function Contact() {
  return <Form />;
}

export default Contact;
